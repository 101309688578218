import React from 'react'
import './Working.css'
import Images from '../../Data/Images';

const Working = () => {
  return (
    <div className='working'>
       <div className="working-1">
          <div className="workingtext">
            <div className="workingtext1">
            <span>Working at Paragon</span>
            <p>Paragon Management is dedicated to helping each security guard succeed. Their <br/> success is our success.</p>
            </div>
          </div>
          <div className="workingimg">
          <img src={Images.consulting} alt="" />
          </div>
       </div>
       <div className="working-2">
          <div className="imageline">
            <div className="workimage">
                <img src={Images.licensed} alt="" />
            </div>
            <div className="textline">
                <div className="emptyline"></div>
                <div className="textgrid">
                    <span>Call or visit a Paragon Career Centre today. There <br/> is no appointment required during regular <br/> business hours. We have a wide variety of <br/> available roles and jobsites.</span>
                </div>
            </div>
          </div>
          <div className="retailmalls">
            <div className="retailtext">
                <span>We are now hiring Licensed <br/> Security Professionals for:</span>
            </div>
            <div className="retailicons">
                <div className="retailicongrid">
                    <img src={Images.home} alt="" />
                    <p>Retail Malls</p>
                </div>
                <div className="retailicongrid">
                    <img src={Images.home} alt="" />
                    <p>Retail Malls</p>
                </div>
                <div className="retailicongrid">
                    <img src={Images.home} alt="" />
                    <p>Retail Malls</p>
                </div>
                <div className="retailicongrid">
                    <img src={Images.home} alt="" />
                    <p>Retail Malls</p>
                </div>
                <div className="retailicongrid">
                    <img src={Images.home} alt="" />
                    <p>Retail Malls</p>
                </div>
                <div className="retailicongrid">
                    <img src={Images.home} alt="" />
                    <p>Retail Malls</p>
                </div>
            </div>
          </div>
       </div>
       <div className="working-3">
         <div className="criteria">
            <span>Criteria for employment includes</span>
            <br/>
            <p>If you do not have a valid Ontario Security Licence, we can help you train and apply for one.</p>
         </div>
         <div className="criteriagrid">
            <div className="castgrid">
                <img src={Images.book2} alt="" />
                <span>A valid Ontario Security Licence</span>
            </div>
            <div className="castgrid">
                <img src={Images.book2} alt="" />
                <span>A valid Ontario Security Licence</span>
            </div>
            <div className="castgrid">
                <img src={Images.book2} alt="" />
                <span>A valid Ontario Security Licence</span>
            </div>
            <div className="castgrid">
                <img src={Images.book2} alt="" />
                <span>A valid Ontario Security Licence</span>
            </div>
            <div className="castgrid">
                <img src={Images.book2} alt="" />
                <span>A valid Ontario Security Licence</span>
            </div>
            <div className="castgrid">
                <img src={Images.book2} alt="" />
                <span>A valid Ontario Security Licence</span>
            </div>
            <div className="castgrid">
                <img src={Images.book2} alt="" />
                <span>A valid Ontario Security Licence</span>
            </div>
            <div className="castgrid">
                <img src={Images.book2} alt="" />
                <span>A valid Ontario Security Licence</span>
            </div>
         </div>
       </div>
       <div className="working-4">
         <div className="committedtext">
            <div className="committedtext1">
            <span>We Develop Professionals</span>  
            <p>Paragon Security is committed to providing the highest levels of training to its <br/> security personnel and management team. We have outsourced our training by <br/> partnering with a professional and nationally recognized education and training <br/> institute. Paragon Training Department was established in 1998 and has been <br/> providing holistic, compliant, and practical security training to frontline security <br/> personnel. It has over 100 training certification programs and professional <br/> designations in both security and management that have been approved by major <br/> Canadian Universities.</p>
            <p>It offers advanced security training programs (both vocational and non-vocational <br/> training) through various platforms such as Self-Paced Online Courses (SPOC), <br/> instructor led Virtual Training Rooms (VTR), and In-Classroom Training (ICT).</p>
            </div>
         </div>
         <div className="committedimg">
            <img src={Images.wedevelop} alt="" />
         </div>
       </div>
       <div className="working-5">
         <div className="successimg">
            <img src={Images.biggest} alt="" />
         </div>
         <div className="successtext">
            <div className="successtext1">
            <span>Supporting Your Success</span>  
            <p>Each new employee is matched with a Paragon Career Centre Representative to <br/> monitor their development and ensure their success. Employees are assigned a <br/> jobsite that best matches their abilities and individual needs. Regular assessments <br/> ensure it’s a match for both employee and client.</p>
            <p>Patrol team and 24/7 Operations Centre ensures that no one is ever alone on the job, <br/> even on a solo shift. We stay connected to our people through regular spot checks <br/> and hourly check-ins.</p>
            <p>Management recognizes top performers because the service teams are small and <br/> agile. We promote existing personnel to more challenging positions with the <br/> opportunity for higher compensation and/or career development.</p>
            </div>
         </div>
       </div>
       <div className="working-6">
          <div className="employeetesti">
            <span>Employee Testimonials</span>
          </div>
          <br/>
          <div className="employeetestimg">
            <img src={Images.firstaid} alt="" />
            <img src={Images.firstaid} alt="" />
          </div>
       </div>
    </div>
  )
}

export default Working