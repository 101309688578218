import React from 'react'
import './Contact.css'
import Images from '../../Data/Images';

const Contact = () => {
  return (
    <div className='contactgrid'>
        <div className="contactgrid1">
           <div className="contactbox">
              <div className="contactline"></div>
              <div className="aboutcontact">
                <span>Brampton Office</span>
                <p>24 Sanford Crescent,<br/> Brampton,<br/> Ontario, L6X 2C3, Canada</p>
                <br/>
                <p1><img src={Images.phone} alt="" />+1 (437) 9850 552</p1>
                <div className="aboutbtns">
                    <a href="mailto: help@newwaysecurity.com">Ask a Question</a>
                    <a href="tel: +14372863002">Contact HR</a>
                    <a href="mailto: info@newwaysecurity.com">Contact Training</a>
                    <a href="mailto: info@newwaysecurity.com">Obtain a Quote</a>
                </div>
              </div>
           </div>
           <div className="contactbox">
              <div className="contactline"></div>
              <div className="aboutcontact">
                <span>Brampton Office</span>
                <p>24 Sanford Crescent,<br/> Brampton,<br/> Ontario, L6X 2C3, Canada</p>
                <br/>
                <p1><img src={Images.phone} alt="" />+1 (437) 9850 552</p1>
                <div className="aboutbtns">
                    <a href="mailto: help@newwaysecurity.com">Ask a Question</a>
                    <a href="tel: +14372863002">Contact HR</a>
                    <a href="mailto: info@newwaysecurity.com">Contact Training</a>
                    <a href="mailto: info@newwaysecurity.com">Obtain a Quote</a>
                </div>
              </div>
           </div>
        </div>
        <div className="contactform">
          <div className="formimg">
            <img src={Images.consec} alt="" />
          </div>
          <div className="formm">
            <div className="conhead">
              <span>Contact Us</span>
            </div>
          <form className='conform' action="">
            <input type="text" placeholder='Name' name='name' required />
            <input type="text" placeholder='Email Address' name='email' required />
            <input type="text" placeholder='Phone Number' name='phonenumber' required />
            <input type="text" placeholder='Subject' name='subject' required />
            <input type="text" placeholder='Message' name='message' required />
            <button>Submit</button>
          </form>
          </div>
        </div>
    </div>
  )
}

export default Contact