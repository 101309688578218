import React from "react";
import "./Condominium.css";
import Images from "../../Data/Images";

const Condominium = () => {
  return (
    <div className="parking">
      <div className="parking-1">
        <div className="parking2">
          <div className="parking1">
            <span>
              Condominium <span1>Services</span1>
            </span>
            <p>
              Condominium living is synonymous with comfort, convenience, and a
              sense of community. At New Way Security, we understand that the
              safety and well-being of condominium residents are paramount. Our
              dedicated security concierge services are designed to not only
              ensure a secure living environment but also to provide a welcoming
              atmosphere that makes tenants feel more at home.Explore how our
              security personnel, trained to handle emergency situations with
              precision, contribute to the safety and comfort of condominium
              residents.
            </p>
          </div>
        </div>
        <div className="parkingimg">
          <img src={Images.serviceback} alt="" />
        </div>
      </div>
      <div className="service">
        <div className="service-1">
          <span>
          Condominium <span1>Services</span1>
          </span>
        </div>
        <div className="service-2">
          <div className="add1">
            <div className="addimg">
              <img src={Images.h1} alt="" />
            </div>
            <div className="addgrid">
              <span1>The Role of Security Concierge Services</span1>
              <p>
                In a condominium setting, a security concierge serves as the
                first point of contact for residents and visitors alike. Beyond
                traditional security responsibilities, our security concierge
                services extend to creating a positive living experience by
                fostering a sense of community, assisting with various needs,
                and, most importantly, ensuring the safety of everyone within
                the premises.
              </p>

            </div>
          </div>
          <div className="add1">
            <div className="addimg">
              <img src={Images.condo1} alt="" />
            </div>
            <div className="addgrid">
              <span1>Trained for Emergency Situations</span1>
              <p>
                One of the core strengths of our security concierge team is
                their extensive training in handling emergency situations.
                Recognizing that quick and effective response is crucial in
                life-threatening events, our personnel are well-versed in
                identifying when it's appropriate to call emergency services.
                Whether someone is seriously injured, experiencing a heart
                attack, or facing any other life-threatening event, our security
                concierge team follows stringent protocols to ensure a swift and
                coordinated response.
              </p>

            </div>
          </div>
          <div className="add1">
            <div className="addimg">
              <img src={Images.condo2} alt="" />
            </div>
            <div className="addgrid">
              <span1>Emergency Calling Protocols</span1>
              <p>
                Our security concierge services include clear guidelines on when
                to initiate emergency calls and how to follow established
                protocols in critical situations. This involves prompt
                communication with emergency services, providing accurate and
                detailed information about the situation, and facilitating
                coordination with medical professionals or relevant authorities.
                By adhering to these protocols, our security concierge team
                ensures that assistance is summoned without delay, potentially
                saving lives in urgent situations.
              </p>

            </div>
          </div>
          <div className="add1">
            <div className="addimg">
              <img src={Images.condo3} alt="" />
            </div>
            <div className="addgrid">
              <span1>Professionalism and Compassion</span1>
              <p>
                While trained to handle emergencies with efficiency, our
                security concierge team also understands the importance of
                professionalism and compassion. In times of distress, the
                demeanor and approach of our personnel play a crucial role in
                comforting individuals affected by the situation. Our security
                concierge services prioritize a human touch, ensuring that
                residents feel supported and cared for during challenging
                moments.
              </p>

            </div>
          </div>
          <div className="add1">
            <div className="addimg">
              <img src={Images.condo4} alt="" />
            </div>
            <div className="addgrid">
              <span1>Creating a Safe and Welcoming Environment</span1>
              <p>
                Beyond emergency response, our security concierge services are
                focused oncreating a safe and welcoming environment within the
                condominium premises. A visible and approachable security
                presence contributes to the overall sense of security for
                residents. Our security concierge team is trained to balance
                vigilance with friendliness, fostering a community atmosphere
                that enhances the quality of condominium living.
              </p>

            </div>
          </div>
          <div className="add1">
            <div className="addimg">
              <img src={Images.condo5} alt="" />
            </div>
            <div className="addgrid">
              <span1>Assistance with Various Needs</span1>
              <p>
                In addition to emergency response, our security concierge team
                is equipped to assist residents with various needs. Whether it's
                providing information, coordinating deliveries, or offering
                guidance on community amenities, our personnel act as a valuable
                resource for residents. By extending a helping hand in
                non-emergency situations, our security concierge services
                contribute to the overall comfort and satisfaction of
                condominium residents.
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Condominium;
