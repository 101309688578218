import logo from "../Assets/logo.jpeg";
import security from "../Assets/security.jpg";
import teampic from "../Assets/teampic.jpg";
import personnel from "../Assets/personnel.jpg";
import consulting from "../Assets/consulting.jpg";
import career from "../Assets/career.png";
import logopng from "../Assets/logopng.png";
import cam from "../Assets/cam.jpg";
import book from "../Assets/book.png";
import clipboard from "../Assets/clipboard.png";
import humanbrain from "../Assets/humanbrain.png";
import uniformed from "../Assets/uniformed.jpg";
import training from "../Assets/training.jpg";
import ontario from "../Assets/ontario.jpg";
import onlineclass from "../Assets/onlineclass.jpg";
import firstaid from "../Assets/firstaid.jpg";
import ministry from "../Assets/ministry.jpg";
import oboarding from "../Assets/oboarding.jpg";
import specialized from "../Assets/specialized.jpg";
import phone from "../Assets/phone.png";
import background from "../Assets/background.png";
import h1 from "../Assets/h1.jpg";
import teamus from "../Assets/teamus.jpg";
import achieve from "../Assets/achieve.png";
import president from "../Assets/president.jpg";
import licensed from "../Assets/licensed.jpg";
import home from "../Assets/home.png";
import book2 from "../Assets/book2.png";
import wedevelop from "../Assets/wedevelop.jpg";
import biggest from "../Assets/biggest.jpg";
import exp from "../Assets/image/experience.png";
import solve from "../Assets/image/solving.png";
import Technology from "../Assets/image/Technology.png";
import collaborate from "../Assets/image/collaborate.png";
import hours from "../Assets/image/24-hours.png";
import consec from "../Assets/image/security.jpeg";
import homecontact from "../Assets/image/Contact.jpeg";
import service1 from "../Assets/image/Personnel.jpg";
import service2 from "../Assets/image/Personnel1.jpg";
import service3 from "../Assets/image/Personnel2.jpg";
import service4 from "../Assets/image/Personnel3.jpg";
import service5 from "../Assets/image/Personnel4.jpg";
import service6 from "../Assets/image/Personnel5.jpg";
import expert from "../Assets/image/Team.jpg";
import condo1 from "../Assets/image/emergency.jpg";
import condo2 from "../Assets/image/calling.jpg";
import condo3 from "../Assets/image/login-banner1.jpg";
import condo4 from "../Assets/image/welcoming.jpg";
import condo5 from "../Assets/image/assistance.jpg";
import serviceback from "../Assets/image/service-back.png";
import event2 from "../Assets/image/crowd.jpg";
import event1 from "../Assets/image/tailored.jpg";
import patrol from "../Assets/image/mobile-patrols.jpg";
import patrol1 from "../Assets/image/proactive.jpg";
import parking from "../Assets/image/p1.jpg";
import parking1 from "../Assets/image/p2.jpg";
import parking2 from "../Assets/image/p3.jpg";
import parking3 from "../Assets/image/p4.jpg";
import parking4 from "../Assets/image/p5.jpg";
import parking5 from "../Assets/image/p6.jpg";
import residental from "../Assets/image/r1.jpg";
import residental1 from "../Assets/image/r2.jpg";
import residental2 from "../Assets/image/r3.jpg";
import residental3 from "../Assets/image/r4.jpg";
import residental4 from "../Assets/image/r5.jpg";
import residental5 from "../Assets/image/r6.jpg";
import useof from "../Assets/image/u1.jpeg"
import useof1 from "../Assets/image/u2.jpg"
import useof2 from "../Assets/image/u3.jpeg"
import useof3 from "../Assets/image/u4.jpg"
import useof4 from "../Assets/image/u5.png"

export default {
  logo,
  security,
  teampic,
  personnel,
  consulting,
  career,
  logopng,
  cam,
  book,
  clipboard,
  humanbrain,
  uniformed,
  training,
  ontario,
  onlineclass,
  firstaid,
  ministry,
  oboarding,
  specialized,
  phone,
  background,
  h1,
  teamus,
  achieve,
  president,
  licensed,
  home,
  book2,
  wedevelop,
  biggest,
  exp,
  solve,
  Technology,
  collaborate,
  hours,
  consec,
  homecontact,
  expert,
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  condo1,
  condo2,
  condo3,
  condo4,
  condo5,
  serviceback,
  event1,
  event2,
  patrol,
  patrol1,
  parking,
  parking2,
  parking1,
  parking3,
  parking4,
  parking5,
  residental,
  residental1,
  residental2,
  residental3,
  residental4,
  residental5,
  useof,
  useof1,
  useof2,
  useof3,
  useof4,
};
