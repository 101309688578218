import React from "react";
import "./Home.css";
import Images from "../../Data/Images";

const Home = () => {
  return (
    <div className="home">
      <div className="home-1">
        <div className="heading">
          <h3>
            Facing the Future <br /> Together
          </h3>
          <p>
            At New Way Security, we are your trusted partner in safeguarding
            what matters most - your business, your assets, and your peace of
            mind. As the premier security consultancy company in Canada, we
            specialize in delivering tailored security solutions that are as
            unique as your organization's needs.
          </p>
          <a href="#service" className="headbtn">
            Our Services
          </a>
        </div>
        <div className="photo">
          <img src={Images.security} alt="photo" />
        </div>
      </div>

      {/* <section class="sec1">
    <div id="carouselExampleControlsNoTouching" class="carousel slide" data-touch="false" data-interval="false">
        <div class="carousel-inner">
            <div class="sliderH carousel-item active">
                <div class="item">
                    <img src="" alt="" />
                    <div class="carousel-caption">
                        <h1 class=""><span
                                >Canada's Leading </span><br/>Security Consultancy
                        </h1>
                        <p>At New ways security, we are your trusted partner in safeguarding
                            what matters most - your business, your assets, and your peace of mind. As the premier
                            security consultancy company in Canada, we specialize in delivering tailored security
                            solutions that are as unique as your organization's needs.</p>
                     <a href="get_quote">OBTAIN A QUOTE</a>
                     <a href="get_quote"><button type="button" class="btn btn-dark"
                        >OBTAIN A QUOTE</button></a>
                </div>
            </div>
            </div>

            <div class="sliderH carousel-item">
                <img src="" alt="" />
                <div class="carousel-caption">
                    <h1 class=""><span
                            >Canada's Leading </span><br/>Security Consultancy
                    </h1>
                    <p>At New ways security, we are your trusted partner in safeguarding what
                        matters most - your business, your assets, and your peace of mind. As the premier security
                        consultancy company in Canada, we specialize in delivering tailored security solutions that are
                        as unique as your organization's needs.</p>
                        <a href="get_quote"><button type="button" class="btn btn-dark"
                            >OBTAIN A QUOTE</button></a>
                </div>
            </div>

            <div class="sliderH carousel-item">
                <img src="" alt="" />
                <div class="carousel-caption">
                    <h1 class=""><span
                            >Canada's Leading </span><br/>Security Consultancy
                    </h1>
                    <p>At New ways security, we are your trusted partner in safeguarding what
                        matters most - your business, your assets, and your peace of mind. As the premier security
                        consultancy company in Canada, we specialize in delivering tailored security solutions that are
                        as unique as your organization's needs.</p>
                        <a href="get_quote"><button type="button" class="btn btn-dark"
                            >OBTAIN A QUOTE</button></a>
                </div>
            </div>

        </div>
        <button class="carousel-control-prev" type="button" data-target="#carouselExampleControlsNoTouching"
            data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-target="#carouselExampleControlsNoTouching"
            data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </button>
    </div>

</section> */}

      <div className="about-1">
        <div className="teampic">
          <img src={Images.expert} alt="" />
        </div>
        <div className="team-expert">
          <p1>Our Team of Experts</p1>
          <span1>
            Our team of seasoned security professionals brings extensive
            experience and knowledge to the table. With a deep understanding of
            the Canadian security landscape, we provide informed insights and
            effective solutions to address your unique security challenges.
          </span1>
          <div className="btn">
            <a href="">apply for job</a>
            <a href="">register for training</a>
          </div>
        </div>
      </div>

      <div className="service" id="service">
        <div className="service-1">
          <p1>Our Services</p1>
          <p>
            At New Way Security, we offer a comprehensive range of security
            services designed to address various security concerns. Our
            expertise spans across:
          </p>
        </div>
        <div className="service-2">
          <div className="add">
            <div className="addimg">
              <img src={Images.service1} alt="" />
            </div>
            <div className="addgrid">
              <span1>Condominium Services</span1>
              <p>
                Condominium living is synonymous with comfort, convenience, and
                a sense of community...
              </p>
              <a href="/Condominium">read more</a>
            </div>
          </div>
          <div className="add">
            <div className="addimg">
              <img src={Images.service2} alt="" />
            </div>
            <div className="addgrid">
              <span1>Enhancing Security through Mobile Patrol Units</span1>
              <p>
                In an ever-evolving landscape of security challenges, businesses
                and property owners...
              </p>
              <a href="/Patrol">read more</a>
            </div>
          </div>
          <div className="add">
            <div className="addimg">
              <img src={Images.service3} alt="" />
            </div>
            <div className="addgrid">
              <span1>Event Security Solutions</span1>
              <p>
                Organizing successful events requires meticulous planning, and
                one crucial aspect that cannot be overlooked is security...
              </p>
              <a href="/Eventsecurity">read more</a>
            </div>
          </div>
          <div className="add">
            <div className="addimg">
              <img src={Images.service5} alt="" />
            </div>
            <div className="addgrid">
              <span1>Comprehensive Residential Security Solutions</span1>
              <p>
                In an era where security concerns are paramount, safeguarding
                one's residence ...
              </p>
              <a href="/Residental">read more</a>
            </div>
          </div>
        </div>
      </div>

      <section className="securitytech">
        <div className="containersecurity">
          <div className="rowsec">
            <div className="sectech1">
              <h2>
                <span>Security Technology </span>
                <br /> Integration
              </h2>
            </div>
            <div className="sectech">
              <div className="cardsec">
                <h3>Security Audits</h3>
                <p>
                  We conduct thorough security audits to evaluate your existing
                  security measures and identify areas for improvement.
                </p>
              </div>
            </div>
            <div className="sectech">
              <div className="cardsec">
                <h3>Security Planning</h3>
                <p>
                  Our experts work closely with you to create comprehensive
                  security plans, ensuring that every aspect of your
                  organization is protected.
                </p>
              </div>
            </div>
            <div className="sectech">
              <div className="cardsec">
                <h3>Security Technology Integration</h3>
                <p>
                  We specialize in implementing and managing the latest security
                  technologies, including surveillance systems, access control,
                  and more.
                </p>
              </div>
            </div>
            <div className="sectech">
              <div className="cardsec">
                <h3>Compliance Guidance</h3>
                <p>
                  We help you navigate the complex world of security
                  regulations, making sure your organization remains in
                  compliance with the law.
                </p>
              </div>
            </div>

            <div className="sectech">
              <div className="cardsec">
                <h3>Risk Assessment and Mitigation</h3>
                <p>
                  We conduct detailed risk assessments and develop strategies to
                  mitigate potential threats, allowing you to proactively
                  protect your assets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="about-us">
        <div className="aboutsec">
          <div className="newway1">
            <div className="newway">
              <p>A Little About Us</p>
              <span>
                Welcome to New Way Security, your trusted partner in ensuring
                unparalleled safety and security. As a prominent security
                consultancy company based in Canada, we take immense pride in
                our unwavering commitment to safeguarding your assets and
                providing comprehensive security solutions tailored to your
                unique needs.
                <br />
                <br />
                At New Way Security, our mission is clear: to create a safer
                environment for individuals, businesses, and communities. We
                strive to achieve this by employing cutting-edge technology,
                industry expertise, and unwavering dedication. Our team of
                seasoned professionals is driven by the belief that everyone
                deserves to feel secure, and we work tirelessly to deliver
                innovative security solutions that exceed expectations.
              </span>
              <br />
              <a href="About">more about us</a>
            </div>
          </div>
          <div className="newway2">
            <div className="newway">
              <div className="newway-para">
                <p1>
                  Our vision at New Ways Security is to redefine the standards
                  of security consultancy, setting new benchmarks in the
                  industry. We aim to be the most trusted and reliable security
                  partner for our clients, offering unmatched expertise,
                  top-notch services, and personalised solutions. By
                  continuously evolving and adapting to emerging threats and
                  challenges, we aspire to build a safer world for all:
                </p1>
              </div>
              <div className="buttons">
                <a href="/Condominium" className="buttons1">
                  <img src={Images.logo} alt="" />
                  <span>Condominium</span>
                </a>
                <a href="/Eventsecurity" className="buttons1">
                  <img src={Images.logo} alt="" />
                  <span>Event Security</span>
                </a>
                {/* <a href="/Parking" className="buttons1">
                  <img src={Images.logo} alt="" />
                  <span>Parking</span>
                </a> */}
                <a href="/Useofforcetraining" className="buttons1">
                  <img src={Images.logo} alt="" />
                  <span>Training</span>
                </a>
                {/* <a href="/Patrol" className="buttons1">
                  <img src={Images.logo} alt="" />
                  <span>Mobile Patrol</span>
                </a> */}
                <a href="/Residental" className="buttons1">
                  <img src={Images.logo} alt="" />
                  <span>Residental</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="homecontact">
        <div className="homecimg">
          <img src={Images.homecontact} alt="contact" />
        </div>
        <div className="homecomtoday">
          <span>
            Contact Us <span1>Today</span1>
          </span>
          <p>
            Ready to take the first step towards enhancing your organization's
            security? Contact us today for a consultation. Together, we'll build
            a safer, more secure future for your business and assets.Trust New
            ways security to be your security consultancy partner in Canada.
            Your security is our priority, and we're here to protect what
            matters most to you.Feel free to customize and adapt the content to
            best represent your security consultancy company's unique strengths
            and services.
          </p>
          <a href="/Contact">Obtain a Quote</a>
        </div>
      </div>
    </div>
  );
};

export default Home;
