import React from "react";
import "./Residental.css";
import Images from "../../Data/Images";

const Residental = () => {
  return (
    <div className="parking">
      <div className="parking-1">
        <div className="parking2">
          <div className="parking1">
            <span>
              Comprehensive Residential Security <span1>Solutions</span1>
            </span>
            <p>
              Comprehensive Security Concierge Services for Enhanced Tenant
              Safety and Comfort <br /> <br /> In an era where security concerns
              are paramount, safeguarding one's residence is a top priority for
              homeowners. Residential Security Solutions, tailored to meet the
              unique needs of individual households, have become an
              indispensable part of creating a secure living environment. Our
              dedicated team of trained security personnel specializes in
              providing on-site protection and surveillance, offering a visible
              and proactive security presence that ensures the safety and
              tranquility of your home.
            </p>
          </div>
        </div>
        <div className="parkingimg">
          <img src={Images.serviceback} alt="" />
        </div>
      </div>
      <div className="service">
        <div className="service-1">
          <span>
            The Importance of Residential <span1>Security</span1>
          </span>
          <p>
            Home is not only where the heart is but also where safety and peace
            of mind should prevail. Residential security goes beyond traditional
            alarm systems; it encompasses a comprehensive approach to
            safeguarding your property, loved ones, and valuables. Our
            Residential Security Solutions are designed to address the evolving
            challenges homeowners face, providing a layer of protection that
            goes beyond the standard security measures.
          </p>
        </div>
        <div className="service-2">
          <div className="add2">
            <div className="addimg">
              <img src={Images.residental} alt="" />
            </div>
            <div className="addgrid">
              <span1>On-Site Protection</span1>
              <p>
                The cornerstone of our Residential Security Solutions is the
                provision of on-site protection by highly trained and
                professional security personnel. Our guards are meticulously
                selected for their expertise, reliability, and commitment to
                ensuring the safety of our clients. With a visible and vigilant
                on-site presence, our security personnel act as a deterrent to
                potential threats, mitigating risks and providing homeowners
                with a sense of security that extends beyond technological
                measures.
              </p>

            </div>
          </div>
          <div className="add2">
            <div className="addimg">
              <img src={Images.residental1} alt="" />
            </div>
            <div className="addgrid">
              <span1>Tailored Security Plans</span1>
              <p>
                Recognizing that each residence is unique, our Residential
                Security Solutions are built on the foundation of tailored
                security plans. Before implementing any security measures, our
                team conducts a thorough assessment of the property, taking into
                consideration its layout, vulnerabilities, and the specific
                concerns of the homeowners. This approach allows us to develop a
                customized security strategy that addresses the individual needs
                of each client.
              </p>

            </div>
          </div>
          <div className="add2">
            <div className="addimg">
              <img src={Images.residental2} alt="" />
            </div>
            <div className="addgrid">
              <span1>Surveillance Expertise</span1>
              <p>
                In addition to on-site protection, our Residential Security
                Solutions include cutting-edge surveillance measures. We deploy
                advanced security technology, such as CCTV cameras, motion
                sensors, and access control systems, to create a comprehensive
                surveillance network. This not only acts as a force multiplier
                for our on-site personnel but also provides continuous
                monitoring of the property, enhancing overall security.
              </p>

            </div>
          </div>
          <div className="add2">
            <div className="addimg">
              <img src={Images.residental3} alt="" />
            </div>
            <div className="addgrid">
              <span1>Visible Deterrence</span1>
              <p>
                The presence of on-site security personnel is a powerful
                deterrent. Potential intruders are less likely to target a
                property with visible security measures in place. Our guards are
                not only trained to respond swiftly to security threats but also
                to conduct proactive patrols, maintaining a visible presence
                that reinforces the security of the residence. This visible
                deterrence is a crucial element in preventing incidents before
                they occur.
              </p>

            </div>
          </div>
          <div className="add2">
            <div className="addimg">
              <img src={Images.residental4} alt="" />
            </div>
            <div className="addgrid">
              <span1>Rapid Response Capability</span1>
              <p>
                Our Residential Security Solutions are built on the principle of
                rapid response. In the event of an alarm activation, suspicious
                activity, or any security concern, our security personnel are
                trained to respond swiftly and effectively. The integration of
                technology, such as GPS tracking and communication systems,
                ensures that our response teams can reach the location promptly,
                minimizing the potential impact of security incidents.
              </p>

            </div>
          </div>
          <div className="add2">
            <div className="addimg">
              <img src={Images.residental5} alt="" />
            </div>
            <div className="addgrid">
              <span1>Professionalism and Training</span1>
              <p>
                The effectiveness of our Residential Security Solutions stems
                from the professionalism and training of our security personnel.
                Our guards undergo rigorous training programs, covering areas
                such as emergency response, conflict resolution, and customer
                service. This ensures that our team is not only adept at
                handling security threats but also capable of interacting with
                residents and visitors in a courteous and professional manner.
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Residental;
