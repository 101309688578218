import React from "react";
import "./Footer.css";
import Images from "../../Data/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="footer">
      <div className="securitysince-1">
        <img src={Images.logo} alt="" />
        <span2>CANADA'S FASTEST GROWING SECURITY COMPANY.</span2>
      </div>
      <div className="securitysince-2">
        <div className="links">
          <div className="link">
            <span1>MAIN LINKS</span1>
            <a href="/">Home</a>
            <a href="/About">Abouts us</a>
            <a href="/Contact">Contact</a>
            <a href="">Terms & Conditions</a>
            <a href="">Privacy Policy</a>
            <a href="">Accessibility</a>
          </div>
        </div>
        <div className="links">
          <div className="link">
            <span1>EMPLOYMENT</span1>
            <a href="">We Develop Professionals</a>
            <a href="">Supporting Your Success</a>
            <a href="">Training</a>
            <a href="">Newsletter</a>
            <a href="">Apply for Job</a>
            <a href="">Login</a>
          </div>
        </div>
        <div className="links">
          <div className="link">
            <span1>SERVICES</span1>
            <a href="/Condominium">Condominium</a>
            <a href="/Eventsecurity">Event Security</a>
            <a href="/Parking">Parking</a>
            <a href="/Education">Education</a>
            <a href="/Training">Training</a>
            <a href="/Residental">Residental</a>
          </div>
        </div>
      </div>
      <div className="securitysince-3"></div>
      <div className="securitysince-4">
        <div className="securitygrid">
          <div className="link">
            <span3>Our Offices</span3>
            <p1>
              24 Sanford Crescent, <br />
              Brampton, Ontario. <br />
              L6X 2C3
            </p1>
            <p1>Tel: +1 (437) 9850 552</p1>
            <p1>Tel: +1 (437) 2863 002</p1>
          </div>
        </div>
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.533620553567!2d-79.7753425!3d43.6786685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b15a8f23b67c1%3A0x3ef9cad501a043ab!2s24%20Sanford%20Crescent%2C%20Brampton%2C%20ON%20L6X%202C3%2C%20Canada!5e0!3m2!1sen!2sin!4v1720192764289!5m2!1sen!2sin"
            width="100%"
            height="350"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="securitysince-3"></div>
      <div className="securitysince-5">
        <div className="copyright">
          <span1>© 2024 New Way Security.</span1>
        </div>
        <div className="socialtags">
          <a href="">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
