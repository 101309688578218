import './App.css';
import { Routes, Route } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Aboutus from './Pages/Aboutus/Aboutus';
import BacktoTop from './Common/BacktoTop/BacktoTop';
import Services from './Pages/Services/Services';
import Training from './Pages/Training/Training';
import Contact from './Pages/Contact/Contact';
import Patrol from './Pages/Patrol/Patrol';
import Parking from './Pages/Parking/Parking';
import Residental from './Pages/Residental/Residental';
import Useof from './Pages/Education/Useof';
import Condominium from './Pages/Condominium/Condominium';
import Eventsecurity from './Pages/Eventsecurity/Eventsecurity';
import Working from './Pages/Working/Working';
import Faq from "./Common/Faq/Faq"

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/Aboutus' element={<Aboutus/>} />
        <Route path='/Services' element={<Services/>} />
        <Route path='/Training' element={<Training/>} />
        <Route path='/Contact' element={<Contact/>} />
        <Route path='/Patrol' element={<Patrol/>} />
        {/* <Route path='/Parking' element={<Parking/>} /> */}
        <Route path='/Residental' element={<Residental/>} />
        {/* <Route path='/Useofforcetraining' element={<Useof/>} /> */}
        <Route path='/Condominium' element={<Condominium/>} />
        <Route path='/Eventsecurity' element={<Eventsecurity/>} />
        <Route path='/Working' element={<Working/>} />
      </Routes>
      <BacktoTop/>
      <Faq/>
      <Footer/>
    </>
  );
}

export default App;
