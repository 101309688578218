import React from "react";
import "./Patrol.css";
import Images from "../../Data/Images";

const Patrol = () => {
  return (
    <div className="parking">
      <div className="parking-1">
        <div className="parking2">
          <div className="parking1">
            <span>Enhancing Security through Mobile Patrol Units</span>
            <p>
              In an ever-evolving landscape of security challenges, businesses
              and property owners are constantly seeking robust solutions to
              safeguard their assets. Mobile patrol units have emerged as a
              dynamic and cost-effective security strategy, offering a flexible
              approach to regular patrols and inspections. Our innovative
              solution provides a proactive and visible deterrent, mitigating
              risks and enhancing the overall security posture of a property.
            </p>
          </div>
        </div>
        <div className="parkingimg">
          <img src={Images.serviceback} alt="" />
        </div>
      </div>
      <div className="service">
        <div className="service-1">
          <span>The Significance of Event Security</span>
          <p>
            Security is a fundamental component of any successful event. Whether
            it's a corporate conference, music festival, sports event, or public
            gathering, the safety of participants, staff, and assets is
            non-negotiable. Event security solutions are designed to address
            potential risks, prevent disruptions, and respond swiftly to
            emergencies, fostering an environment where attendees can focus on
            the event itself without concerns about their well-being.
          </p>
        </div>
        <div className="service-2">
          <div className="add2">
            <div className="addimg">
              <img src={Images.patrol1} alt="" />
            </div>
            <div className="addgrid">
              <span1>Flexibility in Security Operations</span1>
              <p>
                One of the key advantages of mobile patrol units is their
                flexibility in adapting to the unique needs and requirements of
                different properties. Our units can patrol large areas, such as
                industrial complexes, residential neighborhoods, or commercial
                zones, ensuring comprehensive coverage. Their ability to respond
                to emerging threats or changing circumstances makes them an
                invaluable asset for property owners seeking a versatile
                security solution.
              </p>
              
            </div>
          </div>
          <div className="add2">
            <div className="addimg">
              <img src={Images.patrol1} alt="" />
            </div>
            <div className="addgrid">
              <span1>Proactive Deterrence</span1>
              <p>
                The mere presence of mobile patrol units acts as a powerful
                deterrent to potential criminals. Knowing that a property is
                under regular surveillance and patrols dissuades individuals
                with malicious intent from attempting unauthorized activities.
                This proactive approach to security can significantly reduce the
                likelihood of incidents, providing peace of mind to property
                owners and occupants alike.
              </p>
              
            </div>
          </div>
          <div className="add2">
            <div className="addimg">
              <img src={Images.patrol} alt="" />
            </div>
            <div className="addgrid">
              <span1>Cost-Effectiveness</span1>
              <p>
                Our mobile patrol units offer a cost-effective solution. Fixed
                security infrastructure, such as installing and maintaining a
                network of surveillance cameras, can incur substantial upfront
                and ongoing costs. On the other hand, mobile patrols allow for
                efficient coverage of large areas with fewer personnel,
                optimizing resources and minimizing expenses.
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Patrol;
