import React from 'react'
import './Training.css'
import Images from '../../Data/Images';

const Training = () => {
  return (
    <div className='training'>
        <div className="training1">
            <div className="basictraining">
                <span>Basic Security Training</span> 
                <p>At Paragon Security, we ensure that you successfully obtain your Ontario Security <br/> Guard License. Our objective is to simplify the process. Our team of experienced <br/> trainers work directly with you, providing all the necessary information and tools to <br/> achieve success. We provide the option to retake the course or any module for free to <br/> guarantee that you pass the Ministry test.</p>
                <p>Upon completion of our Basic Security Training (BST) program, participants will acqui <br/> re comprehensive knowledge of the Security Industry. This includes:</p>
            </div>
            <div className="basictrainimg">
                <img src={Images.training} alt="" />
            </div>
        </div>
        <div className="training2">
            <div className="bstoption">
                <span>BST – Learning Options</span>
            </div>
            <div className="ontarioclass">
                <div className="classimg">
                    <img src={Images.ontario} alt="" />
                </div>
                <div className='classgrid'>
                    <span1>Ontario Basic Security Guard <br/> Training (BST) – In-class Program:<br/> ($175)</span1>
                    <p>Our in-class Basic Security Training (BST) program spans 40 hours, catering to all <br/> learning styles. For those who prefer structured, interactive learning experiences – our <br/> in-class program is the ideal option.</p>
                    <p>Paragon’s BST course is available on a weekly basis, from Monday to Friday, 8:00 <br/> AM to 5:00 PM, at our Head Office in Toronto. We ensure that our course availability meets <br/> the demands of our clients, offering convenience and flexibility for all our learners.</p>
                    <a href="">register & pay for class</a>
                </div>
            </div>
        </div>
        <div className="training3">
            <div className="onlineclass">
            <span1>Ontario Basic Security Guard <br/> Training (BST) – In-class Program:<br/> ($175)</span1>
            <p>Recognizing that individuals lead busy lives, our Online Basic Security Training course <br/>  is designed to be self-paced, comprised of ten modules. To successfully complete the <br/>course, students must finish all ten online modules, which equate to approximately <br/> 32 hours of training.</p>
            <p>Following completion of the online modules, students are required to attend an <br/> eight-hour First Aid / CPR Level C Certification course to acquire the necessary skills <br/> for emergency situations. Our Online BST course is a convenient option, providing <br/> learners with the flexibility to fit training around their schedules.</p>
            <a href="">register & pay for class</a>
            </div>
            <div className="onlineimg">
                <img src={Images.onlineclass} alt="" />
            </div>
        </div>
        <div className="training4">
            <div className="firstaidimg">
                <img src={Images.firstaid} alt="" />
            </div>
            <div className="firstaidgrid">
                <span>Emergency First Aid</span>
                <p>At Paragon, our Emergency First Aid / CPR-C course offers a combination of online <br/> and in-person learning. The in-person section takes place in either Toronto or <br/> Mississauga, providing learners with practical, hands-on experience.</p>
                <p>If you currently possess a valid Emergency First Aid (EFA) certificate, we offer you the <br/> opportunity to present it to our administrator after registering for the BST program. <br/> This option is beneficial for individuals who wish to refresh their skills and knowledge <br/> while saving time and cost.</p>
            </div>
        </div>
        <div className="training5">
            <div className="ministrygrid">
                <span>Ministry Test and License <br/> Application</span>
                <p>Upon the successful completion of our BST program, our team administrator will <br/> provide you with assistance in scheduling the Ministry test and guide you through <br/> the licence application process. We strive to simplify the process for our learners,<br/> ensuring that they receive support from our team every step of the way.</p>
            </div>
            <div className="ministryimg">
                <img src={Images.ministry} alt="" />
            </div>
        </div>
        <div className="training6">
            <div className="oboardingimg">
                <img src={Images.oboarding} alt="" />
            </div>
            <div className="oboardinggrid">
                <span>Onboarding For Success</span>
                <p>At Paragon Security, we prioritize the success of our employees. We understand that <br/> the success of our team members directly impacts our overall success as a company.</p>
                <p>To make things easy for our employees, we offer fast and effective skill-building <br/> programs that prepare them to be effective security professionals. Our training <br/> programs provide practical, useful security knowledge to ensure that team members <br/> are equipped with the necessary skills to represent our brand and provide <br/> outstanding service to our clients.</p>
                <p>As part of our orientation and onboarding process, every Paragon employee receives <br/>comprehensive training in the following areas:</p>
            </div>
        </div>
        <div className="training7">
            <div className="specialized">
                <span>Specialized Training</span>
                <p>At Paragon Security, we take great pride in understanding the needs of our clients. <br/> We recognize that each client has unique security requirements, and we strive to <br/> provide tailored solutions to meet their needs.</p>
                <p>To ensure that we can deliver on our promise of exceptional service, we provide our <br/> employees with specialized training and the necessary skills to work effectively in <br/> various environments. Our security personnel receive customized training for each <br/> service vertical to ensure they are prepared and confident in their unique work <br/> environment.</p>
                <p>Whether it’s residential, commercial, or institutional security, we equip our team <br/> members with the knowledge and skills to provide exceptional service to our clients. <br/> Our training programs cover a wide range of topics, including:</p>
            </div>
            <div className="specializedimg">
                <img src={Images.specialized} alt="" />
            </div>
        </div>
        <div className="training8"></div>
        <div className="training9"></div>
        <div className="training10"></div>
        <div className="training11"></div>
        <div className="training12"></div>
    </div>
  )
}

export default Training