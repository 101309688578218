import React from "react";
import "./Eventsecurity.css";
import Images from "../../Data/Images";

const Eventsecurity = () => {
  return (
    <div className="parking">
      <div className="parking-1">
        
        <div className="parking2">
          <div className="parking1">
            <span>
              Event Security <span1>Solutions</span1>
            </span>
            <p>
              Organizing successful events requires meticulous planning, and one
              crucial aspect that cannot be overlooked is security. From
              corporate gatherings to public events, the need for a reliable
              event security team is paramount. These professionals are tasked
              with ensuring the safety of attendees, managing crowd control,
              overseeing access points, and coordinating emergency responses.
              Our event security solutions play a vital role in maintaining a
              secure environment while facilitating the smooth operation of
              diverse events.
            </p>
          </div>
        </div>
        <div className="parkingimg">
          <img src={Images.serviceback} alt="" />
        </div>
      </div>
      <div className="service">
        <div className="service-1">
          <span>The Significance of Event Security</span>
          <p>
            Security is a fundamental component of any successful event. Whether
            it's a corporate conference, music festival, sports event, or public
            gathering, the safety of participants, staff, and assets is
            non-negotiable. Event security solutions are designed to address
            potential risks, prevent disruptions, and respond swiftly to
            emergencies, fostering an environment where attendees can focus on
            the event itself without concerns about their well-being.
          </p>
        </div>
        <div className="service-2">
          <div className="add2">
            <div className="addimg">
              <img src={Images.event1} alt="" />
            </div>
            <div className="addgrid">
              <span1>Tailored Security Plans for Different Events</span1>
              <p>
                In a condominium setting, a security concierge serves as the
                first point of contact for residents and visitors alike. Beyond
                traditional security responsibilities, our security concierge
                services extend to creating a positive living experience by
                fostering a sense of community, assisting with various needs,
                and, most importantly, ensuring the safety of everyone within
                the premises.
              </p>
            </div>
          </div>
          <div className="add2">
            <div className="addimg">
              <img src={Images.event2} alt="" />
            </div>
            <div className="addgrid">
              <span1>Crowd Control Expertise</span1>
              <p>
                Managing crowds is a nuanced skill that our event security teams
                bring to the table. Whether it's guiding participants through
                entry points, monitoring crowd density, or facilitating smooth
                transitions between event areas, we can effectively control
                crowds, prevent chaos and maintain a positive atmosphere. Event
                security professionals are trained to anticipate and address
                crowd-related challenges, ensuring a seamless experience for all
                attendees.
              </p>
            </div>
          </div>
          <div className="add2">
            <div className="addimg">
              <img src={Images.h1} alt="" />
            </div>
            <div className="addgrid">
              <span1>The Role of Security Concierge Services</span1>
              <p>
                Controlling access points is a critical aspect of event
                security. This involves not only verifying the credentials of
                individuals entering the venue but also monitoring and securing
                various entry and exit points. Our event security teams
                implement access control measures to prevent unauthorized entry,
                ensuring that only those with valid credentials gain access to
                restricted areas. This proactive approach safeguards against
                potential security breaches and enhances the overall security
                posture of the event.
              </p>
            </div>
          </div>
          <div className="add2">
            <div className="addimg">
              <img src={Images.h1} alt="" />
            </div>
            <div className="addgrid">
              <span1>The Role of Security Concierge Services</span1>
              <p>
                While prevention is a key focus, our event security teams are
                also well-prepared to respond to emergencies swiftly and
                effectively. This includes incidents such as medical
                emergencies, fire, natural disasters, or security threats. The
                implementation of comprehensive emergency response protocols
                ensures that the security team can coordinate with relevant
                authorities, provide assistance to those in need, and mitigate
                the impact of unforeseen events on the overall success of the
                occasion.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eventsecurity;
