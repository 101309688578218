import React from "react";
import "./Aboutus.css";
import Images from "../../Data/Images";

const About = () => {
  return (
    <div className="history">
      <div className="history-1">
        <div className="aboutus1">
          <span>About Us</span>
        </div>
        <div className="aboutus2">
          <p>
            New Way Security is Canada's Fastest Growing Security Company.
          </p>
        </div>
      </div>
      <div className="history-2">
        <div className="historygrid1">
          <p>
            Welcome to New Ways Security, your trusted partner in ensuring
            unparalleled safety and security. As a prominent security
            consultancy company based in Canada, we take immense pride in our
            unwavering commitment to safeguarding your assets and providing
            comprehensive security solutions tailored to your unique needs.
          </p>
        </div>
      </div>
      <div className="history-3">
        <div className="historygrid2">
          <span>Our Vision</span>
          <p>
            Our vision at New Ways Security is to redefine the standards of
            security consultancy, setting new benchmarks in the industry. We aim
            to be the most trusted and reliable security partner for our
            clients, offering unmatched expertise, top-notch services, and
            personalised solutions. By continuously evolving and adapting to
            emerging threats and challenges, we aspire to build a safer world
            for all.
          </p>
        </div>
      </div>
      <div className="history-4">
        <div className="historygrid">
          <span>Our Mission</span>
          <p>
            At New Ways Security, our mission is clear: to create a safer
            environment for individuals, businesses, and communities. We strive
            to achieve this by employing cutting-edge technology, industry
            expertise, and unwavering dedication. Our team of seasoned
            professionals is driven by the belief that everyone deserves to feel
            secure, and we work tirelessly to deliver innovative security
            solutions that exceed expectations.
          </p>
        </div>
      </div>

      <section className="whyus">
        <div className="whycontainer">
          <div className="whyrow">
            <div className="whytext">
              <h2 className="whyspan">
                Why Choose <span>New Way Security?</span>
              </h2>
            </div>

            <div className="whylist">
              <div className="whycontent">
                <div className="card-list">
                  <div className="listimg">
                    <img src={Images.exp} />
                  </div>

                  <div className="list-content">
                    <h3>The Role of Security Concierge Services</h3>

                    <p>
                      In a condominium setting, a security concierge serves as
                      the first point of contact for residents and visitors
                      alike. Beyond traditional security responsibilities, our
                      security concierge services extend to creating a positive
                      living experience by fostering a sense of community.
                    </p>
                  </div>
                </div>
              </div>
              <div className="whycontent">
                <div className="card-list">
                  <div className="listimg">
                    <img src={Images.solve} />
                  </div>
                  <div className="list-content">
                    <h3>Tailored Security Plans for Different Events</h3>
                    <p>
                      One of the strengths of our professional event security
                      services lies in their ability to craft tailored security
                      plans for each event type. Corporate gatherings may
                      require a discreet yet vigilant approach, focusing on
                      access control and safeguarding sensitive information.
                    </p>
                  </div>
                </div>
              </div>
              <div className="whycontent">
                <div className="card-list">
                  <div className="listimg">
                    <img src={Images.Technology} />
                  </div>
                  <div className="list-content">
                    <h3>Flexibility in Security Operations</h3>
                    <p>
                      One of the key advantages of mobile patrol units is their
                      flexibility in adapting to the unique needs and
                      requirements of different properties. Our units can patrol
                      large areas, such as industrial complexes, residential
                      neighborhoods, or commercial zones, ensuring comprehensive
                      coverage.
                    </p>
                  </div>
                </div>
              </div>
              <div className="whycontent">
                <div className="card-list">
                  <div className="listimg">
                    <img src={Images.collaborate} />
                  </div>
                  <div className="list-content">
                    <h3>Professionalism in Parking Enforcement</h3>
                    <p>
                      Professionalism is at the core of our parking enforcement
                      services. Our guards are meticulously trained to handle a
                      variety of parking-related situations with a courteous and
                      efficient approach.
                    </p>
                  </div>
                </div>
              </div>
              <div className="whycontent">
                <div className="card-list">
                  <div className="listimg">
                    <img src={Images.hours} />
                  </div>
                  <div className="list-content">
                    <h3>On-Site Protection</h3>
                    <p>
                      The cornerstone of our Residential Security Solutions is
                      the provision of on-site protection by highly trained and
                      professional security personnel. Our guards are
                      meticulously selected for their expertise, reliability,
                      and commitment to ensuring the safety of our clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
// #414143
