import React from "react";
import "./Navbar.css";
import Images from "../../Data/Images";
import { GiHamburgerMenu, GiCrossMark } from "react-icons/gi";

const Navbar = () => {
  const [togglemenu, settogglemenu] = React.useState(false);

  return (
    <nav className="navbar">
      <div className="nav-logo">
        <img src={Images.logo} alt="logo" />
      </div>

      <div className="nav-links">
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/Aboutus">About</a>
          </li>
          <li>
            <div class="dropdown">
              <a class="dropbtn">Services</a>
              <div class="dropdown-content">
                <a href="/Condominium">Condominium Services</a>
                <a href="/Eventsecurity">Event Security Solutions</a>
                <a href="/Patrol">Mobile Patrol</a>
                {/* <a href="/Parking">Parking Enforcement</a> */}
                <a href="/Residental">Residental Security</a>
                {/* <a href="/Useofforcetraining">Use of Force Training</a> */}
              </div>
            </div>
          </li>
          <li>
            <a href="/Testimonials">Testimonials</a>
          </li>
          <li>
            <a href="/Contact">Contact</a>
          </li>
        </ul>
      </div>

      <div className="navbar-smallscreen">
        <GiHamburgerMenu
          color="#FF0000"
          className="navbar-menu"
          fontsize={50}
          onClick={() => {
            settogglemenu(true);
          }}
        />
        {togglemenu && (
          <div className="navbar-smallscreen_overlay flex__center slide-bottom">
            <GiCrossMark
              color="#ffffff"
              className="overlay__close"
              onClick={() => {
                settogglemenu(false);
              }}
            />
            <ul className="navbar-smallscreen_links">
              <li>
                <a
                  href="/"
                  onClick={() => {
                    settogglemenu(false);
                  }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/Aboutus"
                  onClick={() => {
                    settogglemenu(false);
                  }}
                >
                  About
                </a>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="dropbtn"
                  >
                    Services
                  </a>
                  <div class="dropdown-content1">
                    <a href="/Condominium" onClick={() => {
                      settogglemenu(false);
                    }}>Condominium Services</a>
                    <a href="/Eventsecurity" onClick={() => {
                      settogglemenu(false);
                    }}>Event Security Solutions</a>
                    {/* <a href="/Patrol" onClick={() => {
                      settogglemenu(false);
                    }}>Mobile Patrol</a> */}
                    <a href="/Parking" onClick={() => {
                      settogglemenu(false);
                    }}>Parking Enforcement</a>
                    <a href="/Residental" onClick={() => {
                      settogglemenu(false);
                    }}>Residental Security</a>
                    {/* <a href="/Useofforcetraining" onClick={() => {
                      settogglemenu(false);
                    }}>Use of Force Training</a> */}
                  </div>
                </div>
              </li>
              <li>
                <a
                  href="/Testimonials"
                  onClick={() => {
                    settogglemenu(false);
                  }}
                >
                  Testimonials
                </a>
              </li>
              <li>
                <a
                  href="/Contact"
                  onClick={() => {
                    settogglemenu(false);
                  }}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
